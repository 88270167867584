import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    selectedIndex: -1,
    isPopup: false,
  },
  mutations: {
    setIndex(state, payload) {
      state.selectedIndex = payload;
    },
    setPopup(state, payload) {
      state.isPopup = payload;
    },
  },
  actions: {
    setIndex({ commit }, payload) {
      commit("setIndex", payload);
    },
    setPopup({ commit }, payload) {
      commit("setPopup", payload);
    },
  },
  modules: {
  }
})
