<template>
  <v-app id="app">
    <transition name="fade" mode="out-in">
      <router-view :key="$route.fullPath"></router-view>
    </transition>
  </v-app>
</template>
<script>
// import './handler/scroll';

export default {
  name: "App",
  data() {
    return {
      menu: [{
        title: 'About Us',
        location: "about"
      }, {
        title: 'Asset Showcase',
        location: "asset"
      }, {
        title: 'Team',
        location: 'team'
      }, {
        title: 'Contact Us',
        location: 'contact'
      }],
      selectedIndex: 0,
    }
  },
}
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300;400;500;600;700&display=swap');

:root {
  --secodnary-color: #001f5f;
  --primary-color: #090d2e;
  --appbar-color: black;
}

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  /* background: var(--primary-color) !important; */
   box-sizing: border-box;
}

#app {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  width: 100%;
  max-width: 100%;
  background: var(--primary-color) !important;
  font-family: 'Orbitron', sans-serif !important;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateX(2em);
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.header {
  background: linear-gradient(90.45deg, #00FFBF -2.93%, #1657DC 95.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: 'Orbitron', sans-serif !important;
  font-weight: 800;
}

.title {
  font-family: 'Orbitron', sans-serif !important;
  line-height: inherit !important;
  letter-spacing: 0px !important;
}

.button {
  background: linear-gradient(90.45deg, #00FFBF -2.93%, #1657DC 95.78%);
  backdrop-filter: blur(4px);
  border-radius: 5px;
  font-family: 'Orbitron', sans-serif;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
}
</style>
